import image1 from "../assets/nefertiti.jpg";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing this agency and by choosing the right plan and program I already achieved my deal!",
    name: 'Nefertiti Natural Beauty',
    status : 'Cosmetic Company'
  },
  {
    image: image2,
    review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint ',
    name: 'Youssef alien',
    status: 'Entrepreneur'
  },
  {
    image : image3,
    review:' Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
    name: 'Oussema',
    status: "Freelancer"
  }
];
