import image1 from "../assets/coding.png";
import image2 from "../assets/social-media.png";
import image3 from "../assets/paint-board-and-brush.png";
import image4 from "../assets/camera.png";
import image5 from "../assets/face.png";



export const programsData = [
  {
    image: image1,
    
    heading: "Web Devolpement:  ",
    details:
      " We turn the best ideas into excellent products.",
  },
  {
    image: image2,
    heading: "Digital Marketing: ",
    details:
      " We help you reach your customers online",
  },
  {
    image: image3,
    heading: "Graphique Designer: ",
    details:
      " Looking for a creative designer to conceive and develop engaging integrated concepts and innovative visual experiences. Hire the best at MP",
  },
  {
    image: image4,
    heading: "Shooting Product: ",
    details:
      " Our team is here to create unique content that looks like your business and stands out from your competitors using content preparation tools",
  },
  {
    image: image5,
    heading: "Facebook Manager: ",
    details:
      " Our team is here to create unique content that looks like your business and stands out from your competitors using content preparation tools",
  },
];